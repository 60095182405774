import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Parallax, Background } from 'react-parallax';

function Projects(){

    const [view, setView] = useState(null)
    const [projectView, setProjectView] = useState(null)
    
    let aboutMe = ({
        strength: null,
        contentClassName: view,
        image: 'aboutMe',
        width: null
    })
    
    const windowAdjust = () => {
        if(window.innerWidth >= 850 && window.innerWidth <= 1680){
            setView('desktop');
            setProjectView('project prDesktop')
        } else if(window.innerWidth >= 1681){
            setView('bigView')
            setProjectView('project prBig')
        } else{
            setView('mobile')
            setProjectView('project prMobile')
        }
      };

    useEffect(() => {
        window.addEventListener("resize", windowAdjust);
        if(window.innerWidth >= 680 && window.innerWidth <= 1680){
            setView('desktop');
            setProjectView('project prDesktop')
        } else if(window.innerWidth >= 1681){
            setView('bigView')
            setProjectView('project prBig')
        } else{
            setView('mobile')
            setProjectView('project prMobile')
        }
    }, []);

    if(view === 'desktop'){
        aboutMe.strength = 750;
        aboutMe.width = window.innerWidth;
    } else if(view === 'bigView'){
        aboutMe.strength = 1250;
        aboutMe.width = window.innerWidth;
    } else {
        aboutMe.strength = 500;
        aboutMe.width = window.innerWidth;
    }

    const Div = styled.div`
        a{
            color: black;
            font-weight: bold;
            font-size: 25px;
            &:hover{
                color: teal;
            }
        }
        .desktop{
            animation: background 1s linear forwards;
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .mobile{
            animation: background 1s linear forwards;
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .bigView{
            animation: background 1s linear forwards;
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .githubLink{
            Color: white;
            font-size: 32px;
            &:hover{
                color: teal;
            }
        }
    `

    return (
        <Div>
            <Parallax blur={0} bgImage={require('../assets/images/aboutMe.JPG')} bgImageStyle={aboutMe.imageSize} bgImageAlt="coding picture" strength={aboutMe.strength} bgClassName={aboutMe.image} contentClassName={aboutMe.contentClassName}>
            <div className='projects'>
                <div className={projectView}>
                    <h3>Kina Moua Photography</h3>
                    <a href="https://www.kinamoua.com/#/" target="_blank"><img src={require('../assets/images/kinamoua.jpg')}></img></a>
                    <div className='info'>
                        <p>This is a website for Kina Moua Photography. Using Reactjs.</p>
                        <a href="https://www.kinamoua.com/#/" target="_blank">View Live Site</a>
                    </div>
                </div>
                <div className={projectView}>
                    <h3>My Budget</h3>
                    <a href="https://mybudget-a3e24.firebaseapp.com/#/" target="_blank"><img src={require('../assets/images/myBudget.jpg')}></img></a>
                    <div className='info'>
                        <p>"My Budget" is a free, no subscription application I created for users to be able to create their own budget but also much more. Users can input their debts they have and keep track of payments they have made and the status of that debt. There is also a savings section for people who want to track how much money they have saved for a car or any other item they want to save up for.</p>
                        <a href="https://mybudget-a3e24.firebaseapp.com/#/" target="_blank">View Live Site</a>
                        <br/>
                        <a href="https://github.com/RMcLeanDev/myBudget" target="_blank">Github</a>
                    </div>
                </div>
            </div>
            <h1 style={{color: "white", textAlign: "center", maring: "0px", padding: "0px"}}>Plus many more on my <a href="https://github.com/RMcLeanDev" target="_blank" className="githubLink">Github profile</a>.</h1>
            </Parallax>
        </Div>
    )
}

export default Projects;